const featuredHosting = {
  1: {
    title: 'Starter Plan',
    discountPercent: 50,
    priceINR: 30,
    priceUSD: 0.5,
    featureLabel: 'Most Popular',
    features: [
      '1 Domain',
      '1GB SSD Disk Space',
      'Unlimited Bandwidth',
      '5 Email Accounts',
      '2 Database',
      '5 Sub-Domain',
      'Free SSL Certificate',
      'Virus Protection'
    ],
    buttonLabel: 'View Plans',
    buttonURL: '/standard-hosting'
  },
  2: {
    title: 'Advanced Plan',
    discountPercent: 50,
    priceINR: 60,
    priceUSD: 0.8,
    featured: true,
    featureLabel: 'Most Popular',
    features: [
      'Value for Money',
      '1 Domain',
      '1GB SSD Disk Space',
      'Unlimited Bandwidth',
      '5 Email Accounts',
      '2 Database',
      '5 Sub-Domain',
      'Free SSL Certificate',
      'Virus Protection'
    ],
    buttonLabel: 'View Plans',
    buttonURL: '/advance-hosting',
    footerText: 'Best Selling Plan'
  },
  3: {
    title: 'Ultimate  Plan',
    discountPercent: 50,
    priceINR: 300,
    priceUSD: 4.7,
    featureLabel: 'Most Popular',
    features: [
      '1 Domain',
      '1GB SSD Disk Space',
      'Unlimited Bandwidth',
      '5 Email Accounts',
      '2 Database',
      '5 Sub-Domain',
      'Free SSL Certificate',
      'Virus Protection'
    ],
    buttonLabel: 'View Plans',
    buttonURL: '/ultimate-hosting'
  }
}
export default featuredHosting
