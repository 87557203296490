import React from 'react'
import Pricing from '../pricing-components/pricing-group'
import featuredPricingData from '../../../content/hosting/featured-hosting'
import PrimaryHeading from '../../heading/primary-heading'

function FeaturedPricing() {
  const title = 'Choose Your Plan'
  const subtitle =
    'Whether it’s for personal or business use, you need a web hosting service like Blue Geek Hosting to get your ideas online. No matter which plan you choose, you can count on us for reliability, security, and a stress-free experience.'

  return (
    <>
      <PrimaryHeading title={title} subtitle={subtitle} id="pricing" />
      <Pricing pricingContent={featuredPricingData} />
    </>
  )
}

export default FeaturedPricing
