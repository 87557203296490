import React from 'react'
import PrimaryHeader from '../components/header/primary/primary-header'
import HeroFeature from '../components/features/hero-feature/hero-feature'
import PartnerBrands from '../components/features/partner-brands/partner-brands'
import FeaturedPricing from '../components/pricing/featured-pricing/featured-pricing'
import DomainSection from '../components/domain/domain-section'
import FaqSection from '../components/faq/faq-section'
import TestimonialSection from '../components/testimonial/testimonial-section'
import FeatureSection from '../components/features/feature-section/feature-section'
import SEO from '../components/seo/seo'
import { Helmet } from 'react-helmet'

const sch = {
  '@context': 'https://schema.org/',
  '@type': 'Restaurant',
  name: 'Trattoria Luigi',
  image: [
    'https://example.com/photos/1x1/photo.jpg',
    'https://example.com/photos/4x3/photo.jpg',
    'https://example.com/photos/16x9/photo.jpg'
  ],
  priceRange: '$$$',
  servesCuisine: 'Italian',
  address: {
    '@type': 'PostalAddress',
    streetAddress: '148 W 51st St',
    addressLocality: 'New York',
    addressRegion: 'NY',
    postalCode: '10019',
    addressCountry: 'US'
  }
}

const IndexPage = () => (
  <>
    <SEO title="Affodable Web Hosting" />
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(sch)}</script>
    </Helmet>
    <PrimaryHeader />
    <HeroFeature />
    <PartnerBrands />
    <FeaturedPricing />
    <DomainSection />
    <FeatureSection />
    <FaqSection />
    <TestimonialSection />
  </>
)

export default IndexPage
