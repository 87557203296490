import React from 'react'
import { PrimaryHeaderWrapper } from './primary-header-styles'
import Button from '../../common/cta-button/button'

import heroRight from '../../../images/illustrations/hero-right.svg'
import heroLeft from '../../../images/illustrations/hero-left.svg'

function PrimaryHeader() {
  return (
    <PrimaryHeaderWrapper>
      <div className="hero-area">
        <div className="hero-content">
          <div className="hero-text">
            <h1>
              Own the{' '}
              <span className="paint-bg">
                internet<span className="accent-pink">.</span>
              </span>
              <br /> Start with fast web hosting
              <span className="accent-pink">.</span>
            </h1>
            <p>
              Whether you’re a website beginner or an expert, our web hosting
              plans offer the flexibility to choose what works best for you.
            </p>
            <Button size="big" href="#pricing">
              Get Started Now
            </Button>
          </div>
          <div className="hero-image">
            <img src={heroRight} alt="Women Handling Server Illustration" />
            <img src={heroLeft} alt="Women Handling Server Illustration" />
          </div>
        </div>
      </div>
    </PrimaryHeaderWrapper>
  )
}

export default PrimaryHeader
