import React from 'react'
import { PartnerBrandWrapper } from './partner-brands-styles'
import brandLogo from '../../../images/icons/partner-icons.svg'
import { Container } from '../../common/common-styles'

function PartnerBrands() {
  return (
    <PartnerBrandWrapper>
      <p>Our Amazing Partners and Vendors</p>
      <Container>
        <svg width="224" height="32" viewBox="0 0 224 32">
          <use href={`${brandLogo}#cloudflare`} />
        </svg>
        <svg width="119" height="38" viewBox="0 0 119 38">
          <use href={`${brandLogo}#litespeed`} />
        </svg>
        <svg width="75" height="59" viewBox="0 0 75 59">
          <use href={`${brandLogo}#lets-encrypt`} />
        </svg>
        <svg width="117" height="33" viewBox="0 0 117 33">
          <use href={`${brandLogo}#linux`} />
        </svg>
        <svg width="129" height="29" viewBox="0 0 129 29">
          <use href={`${brandLogo}#cpanel`} />
        </svg>
      </Container>
    </PartnerBrandWrapper>
  )
}

export default PartnerBrands
