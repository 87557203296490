import React from 'react'
import { DomainSearchWrapper } from './domain-styles'
import Button from '../common/cta-button/button'

function DomainSearch() {
  const handleDomainSearch = e => {
    e.preventDefault()
    if (typeof window !== 'undefined') {
      window.location =
        'https://my.bluegeekhosting.com/cart.php?a=add&domain=register'
    }
  }
  return (
    <DomainSearchWrapper>
      <form action="" onSubmit={handleDomainSearch}>
        <input type="text" placeholder="Your perfect domain name..." />
        <Button size="big" as="button" type="submit" hideArrow>
          Search
        </Button>
      </form>
    </DomainSearchWrapper>
  )
}

export default DomainSearch
