import React from 'react'
import { DomainSectionWrapper } from './domain-styles'
import PrimaryHeading from '../heading/primary-heading'
import { Container } from '../common/common-styles'
import DomainSearch from './domain-search'

function DomainSection() {
  const title = 'Find the perfect domain Name'
  const subtitle =
    'Enter domain name of your choice and pick any extension name on the next step (choose between .com, .xyz, .co, .in, .net and many more'
  return (
    <Container>
      <DomainSectionWrapper>
        <PrimaryHeading title={title} subtitle={subtitle} />
        <DomainSearch />
      </DomainSectionWrapper>
    </Container>
  )
}

export default DomainSection
