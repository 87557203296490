import styled from 'styled-components'
import { Container } from '../../common/common-styles'
import media from '../../utils/breakpoints'
export const PartnerBrandWrapper = styled.div`
  padding: 1.5rem 0;
  p {
    color: #b6bdd3;
    text-align: center;
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: bold;
  }
  ${Container} {
    display: block;
    justify-content: space-between;
    align-items: flex-end;
    padding: 2rem 1rem;
    text-align: center;
    svg {
      user-select: none;
      -webkit-user-drag: none;
      pointer-events: none;
      display: inline-block;
      margin: 0.5rem 1rem;
    }
  }

  ${media.md} {
    padding: 3rem 0;
    ${Container} {
      display: flex;
      svg {
        margin: 0;
      }
    }
  }
`
