import styled from 'styled-components'
import media from '../utils/breakpoints'
import { Container } from '../common/common-styles'

export const DomainSectionWrapper = styled.div`
  padding-bottom: 3rem;
  margin: 6rem 0 3rem;
  background: var(--cool-white);
  border-radius: 10px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  position: relative;
  &:before,
  &:after {
    position: absolute;
    height: 200px;
    width: 150px;
    top: 0;
    left: -72px;
    pointer-events: none;
    user-select: none;
    -webkit-user-drag: none;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='120' height='160' viewBox='0 0 120 160'%3E%3Cg fill='none' fill-rule='evenodd' stroke-linejoin='round' stroke-width='2'%3E%3Cpath d='M4 4c34.667 5.6 44 41.174 28 106.724-10.355 42.421-2.281 55.032 24.22 37.834' stroke='%23FD2D55'%3E%3C/path%3E%3Cpath d='M56.22 148.558c9.632-6.25 29.559-24.489 59.78-54.714' stroke='%23f8f8ff'%3E%3C/path%3E%3C/g%3E%3C/svg%3E")
      center center / contain space;
  }

  &:after {
    right: -82px;
    left: auto;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='120' height='160' viewBox='0 0 120 160'%3E%3Cg fill='none' fill-rule='evenodd' stroke-width='2'%3E%3Cpath d='M71.278 39.28c15.377-14.514 2.5-31.381 16.767-35.101C101.795.594 125.69 42.29 56.235 56.95M55.975 76c23.607 4.392 51.41 9.121 51.41 15.667 0 14.63-37.55 20.637-17.785 22.264 33.36 2.739 34.576 27.553 6.636 37.689-13.283 4.818-27.212 5.144-40.105 3.302' stroke='%23FD2D55'%3E%3C/path%3E%3Cpath d='M56.235 56.95A182.273 182.273 0 0148 58.487c-43.93 7.182-21.178 12.091 7.976 17.515m.155 78.922C27.51 150.836 4 136.071 4 136.071' stroke='%23f8f8ff'%3E%3C/path%3E%3C/g%3E%3C/svg%3E")
      center center / contain space;
    top: 120px;
  }

  ${media.md} {
    margin: 3rem 0;
    &:before,
    &:after {
      content: '';
    }
  }
`
export const DomainSearchWrapper = styled.div`
  form {
    margin: 0 auto;
    display: block;
    max-width: 600px;
    padding: 0 1rem;
    input {
      border: 2px solid var(--blue);
      border-radius: 50px;
      font-size: 1.1rem;
      padding: 0.5rem 1.5rem;
      width: 100%;
      transition: border-color 350ms ease;
      &:active,
      &:focus {
        outline: none;
        border-color: var(--navy2);
      }
    }

    button {
      display: inline-block;
      margin: 1rem 0;
      cursor: pointer;
      width: 100%;
      text-align: center;
      &:active,
      &:focus {
        outline: none;
        background: var(--navy2) !important;
      }
    }
  }

  ${media.md} {
    form {
      display: flex;
      align-items: center;

      input {
        width: calc(100% - 130px);
        flex: 1;
        margin-right: 1rem;
      }
      button {
        width: auto;
        text-align: right;
        flex: 0;
        /* margin-left: -58px; */
      }
    }
  }
`

export const DomainPriceChartWrapper = styled.section`
  ${Container} {
    background: url("data:image/svg+xml,%3Csvg width='937' height='335' viewBox='0 0 937 335' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M56 233C60 293 70 351 36 327C2 303 -8 231 14 219C36 207 -8 109 14 92.9999C36 76.9999 34 -0.999973 72 21C110 43 706 210 730 178C754 146 898.721 247 908 190C913.372 157 867.651 105.834 880 40.9999C896 -43.0002 964 33 920 53.0001' stroke='%2304D5FE' stroke-width='3'/%3E%3C/svg%3E%0A")
      center center / 85% no-repeat;
  }
`
export const DomainPriceChartInner = styled.div`
  background: #fff;
  padding: 2rem 0.5rem;
  max-width: 800px;
  position: relative;
  margin: 0 auto;
  border: 1px solid var(--cool-white);
  border-radius: 10px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  font-size: 1.1rem;
  a {
    background: none !important;
    color: var(--blue) !important;
    padding: 0;
    position: absolute;
    bottom: 10px;
    right: 10px;
    &:hover {
      background: none !important;
      color: var(--navy) !important;
    }
  }

  div.row {
    grid-template-columns: repeat(4, 1fr);
    display: grid;
    padding: 0.5rem;
    div.heading,
    div.tld {
      font-weight: bold;
    }
    div.price {
      span {
        font-size: 0.8rem;
        vertical-align: center;
        line-height: 1.1rem;
      }
    }
  }

  ${media.sm} {
    padding: 2rem 2rem 2rem 6rem;
    font-size: 1rem;
  }
`
