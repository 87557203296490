import styled from 'styled-components'
import media from '../../utils/breakpoints'
import dotsvg from '../../../images/illustrations/dots.svg'

export const PrimaryHeaderWrapper = styled.main`
  div.hero-area {
    div.hero-content {
      div.hero-text {
        position: relative;
        max-width: 700px;
        text-align: center;
        margin: 0 auto;
        h1 {
          font-size: 2rem;
          font-weight: 700;
          color: var(--navy);
          line-height: 1.2;
        }
        p {
          padding: 1rem;
          font-size: 1rem;
        }
        a {
          margin: 1.5rem 0.5rem;
        }
      }
      div.hero-image {
        max-width: 1100px;
        margin: 0 auto;
        display: none;
        padding: 0 2rem;
        justify-content: space-between;
        user-select: none;
        pointer-events: none;
        img {
          display: block;
          width: 200px;
        }
      }
    }
    padding: 5rem 1rem;
    position: relative;
    &::after,
    &::before {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      height: 200px;
      width: 200px;
      background: url(${dotsvg}) center center / cover space;
      user-select: none;
      pointer-events: none;
      z-index: 0;
    }
    &::before {
      top: 0%;
      right: 0;
      left: auto;
    }
  }

  ${media.sm} {
    div.hero-area {
      div.hero-content {
        div.hero-image {
          display: flex;
        }
      }
    }
  }
  ${media.md} {
    div.hero-area {
      div.hero-content {
        div.hero-text {
          h1 {
            font-size: 3rem;
          }
          p {
            font-size: 1.1rem;
          }
        }
        div.hero-image {
          margin: -130px auto 0;
          img {
            width: 250px;
          }
        }
      }
      &:after {
        top: 50%;
      }
      &:before {
        top: 20%;
      }
    }
  }
`
